import BottomSheet from "@/components/bottom-sheet/BottomSheets";
import EntregaForm from "@/components/entrega-form/EntregaForm";
import { OcorrenciaIdBadge } from "@/components/entregas/OcorrenciaIdBadge";
import { StatusIcon } from "@/components/entregas/StatusIcon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useIndexedDB } from "@/hooks/use-indexed";
import { useOnlineStatus } from "@/hooks/use-online-status";
import { useToast } from "@/hooks/use-toast";
import { arrayBufferToBase64 } from "@/lib/utils";
import { ocorrenciaService } from "@/services/ocorrencia.service";
import { useOcorrenciaStore } from "@/store/ocorrencia-store";
import {
  eOcorrenciaStatus,
  OcorrenciaFormData,
  TipoDocumentoTransporte,
  TipoDocumentoViagemTransporte,
} from "@/types/ocorrencia";
import dayjs from "dayjs";
import {
  ArrowLeft,
  Calendar,
  CircleAlert,
  Clock,
  Download,
  Edit2,
  Loader2,
  RefreshCcw,
  ShipIcon,
  Trash2,
  WifiOff,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EntregasVisualizar: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { getByID, update, deleteRecord } = useIndexedDB("entregas");
  const { getFirst } = useIndexedDB("motoristas");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const { setOcorrencia, ocorrencia } = useOcorrenciaStore();
  const [imageURL, setImageURL] = useState<string | null>(null);
  const isOnline = useOnlineStatus();
  const [isOpen, setIsOpen] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
    // The BottomSheet component will handle preventing closure if fields are empty
  };
  const onSave = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    setIsDialogOpen(false);
    deleteRecord(Number(id)).then(() => {
      toast({
        title: "Entrega excluída",
        variant: "success",
      });
      navigate(-1);
    });
  };

  const formatDate = (dateString: string | undefined, format: string) => {
    if (!dateString) return "";
    return dayjs(dateString).format(format);
  };

  useEffect(() => {
    fetchEntrega();
  }, []);

  const fetchEntrega = () => {
    getByID(Number(id)).then((entregaIdb: OcorrenciaFormData) => {
      setOcorrencia(entregaIdb);

      if (entregaIdb?.images.length) {
        const blob = new Blob([entregaIdb.images[0].imageData], {
          type: entregaIdb.images[0].imageType,
        });
        const url = URL.createObjectURL(blob);
        setImageURL(url);
        return () => URL.revokeObjectURL(url);
      }
    });
  };

  const handleSincronizar = async () => {
    const { placa } = await getFirst();
    if (!isOnline) {
      toast({
        description: (
          <div className="flex items-center gap-4">
            <WifiOff size={16} /> Sem conexão com a internet
          </div>
        ),
        variant: "warning",
      });
      return;
    }

    try {
      setIsLoading(true);

      const file = ocorrencia?.images.length
        ? ((await arrayBufferToBase64(
            ocorrencia.images[0].imageData,
            ocorrencia.images[0].imageType
          )) as string)
        : null;

      const entregasZorte = (await ocorrenciaService.create([
        { ...ocorrencia, file },
      ] as OcorrenciaFormData[])) as OcorrenciaFormData[] & {
        error: boolean;
        message: string;
      };

      const error = hasError(entregasZorte);
      const message = getMessage(entregasZorte);

      if (error && ocorrencia?.id) {
        ocorrenciaService.logs({
          placa: placa || "",
          body: [
            {
              ...ocorrencia,
              file: file || "",
              message,
              error,
            },
          ],
        });
      }

      entregasZorte.forEach(async (entrega) => {
        if (entrega.error) {
          await update({
            ...ocorrencia,
            status: eOcorrenciaStatus.SINCRONIZADO,
            message: entrega.message,
            error: true,
          });
        } else {
          const {
            tipo_documento_transporte,
            tipo_documento_viagem_transporte,
            viagem_transporte_id,
            documento_transporte_id,
            documento_ocorrencia_id,
          } = entrega;
          await update({
            ...ocorrencia,
            tipo_documento_transporte,
            tipo_documento_viagem_transporte,
            viagem_transporte_id,
            documento_transporte_id,
            documento_ocorrencia_id,
            status: eOcorrenciaStatus.SINCRONIZADO,
            message: "",
            error: false,
          });
        }
      });
      fetchEntrega();
      setIsLoading(false);
    } catch (error: any) {
      toast({
        title: "Erro ao sincronizar",
        description: error?.message,
        variant: "destructive",
      });
      ocorrenciaService.logs({
        placa,
        error: error,
      });
      setIsLoading(false);
    }
  };

  const hasError = (
    entregasZorte: OcorrenciaFormData[] & {
      error: boolean;
      message: string;
    }
  ) => {
    return (
      (typeof entregasZorte === "object" && entregasZorte?.error) ||
      entregasZorte[0]?.error ||
      false
    );
  };

  const getMessage = (
    entregasZorte: OcorrenciaFormData[] & {
      error: boolean;
      message: string;
    }
  ) => {
    return (
      (typeof entregasZorte === "object" && entregasZorte?.message) ||
      entregasZorte[0]?.message ||
      ""
    );
  };

  return (
    <div className="min-h-screen bg-background font-[family-name:var(--font-geist-sans)] pb-16">
      <header className="bg-blue-500 text-white px-4 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <Button onClick={() => navigate(-1)} size="icon" className="mr-2">
            <ArrowLeft className="h-6 w-6" />
          </Button>
          <div className="max-w-[200px]">
            <h1 className="text-base font-semibold truncate">
              NFe {ocorrencia?.numero_documento}
            </h1>
          </div>
        </div>
        {ocorrencia?.error && (
          <div className="flex gap-2">
            <Button
              onClick={() => setIsOpen(true)}
              size="icon"
              variant={"ghost"}
              className="text-white hover:bg-blue-600"
            >
              <Edit2 className="h-5 w-5" />
            </Button>
            <Button
              onClick={() => setIsDialogOpen(true)}
              size="icon"
              variant={"ghost"}
              className="text-white hover:bg-blue-600"
            >
              <Trash2 className="h-5 w-5" />
            </Button>
          </div>
        )}
      </header>

      {ocorrencia && (
        <>
          <div className="space-y-4 p-4">
            <div className="rounded-lg border">
              <div className="p-4 space-y-4">
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex flex-row gap-4 relative">
                    <StatusIcon
                      status={ocorrencia.status}
                      variant="default"
                      showText={true}
                    />
                    {ocorrencia.status ==
                      eOcorrenciaStatus.NAO_SINCRONIZADO && (
                      <Button
                        disabled={isLoading}
                        onClick={handleSincronizar}
                        variant={"outline"}
                        className="bg-white border absolute right-0 px-3 h-8 top-1/2 -translate-y-1/2"
                      >
                        {isLoading ? (
                          <>
                            <Loader2 className="animate-spin" />
                          </>
                        ) : (
                          <>
                            <RefreshCcw size={16} className="text-green-500" />
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                </div>
                {ocorrencia.message && (
                  <>
                    <div className="w-full border-b border-dashed border-gray-200"></div>
                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex flex-row gap-2">
                        <CircleAlert
                          size={20}
                          color="rgb(194 65 12 / var(--tw-text-opacity, 1))"
                        />
                        <p className="text-sm text-orange-700">
                          {ocorrencia.message}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="rounded-lg border">
              <div className="p-4">
                <div className="grid grid-cols-3 gap-4 pb-4">
                  <div className="flex flex-col text-left">
                    <span className="text-[14px] text-gray-500 mb-1">NF-e</span>
                    <span className="text-[14px] font-semibold text-gray-900 break-all">
                      {ocorrencia.numero_documento}
                    </span>
                  </div>
                  <div className="flex flex-col text-left">
                    <span className="text-[14px] text-gray-500 mb-1">
                      Documento
                    </span>
                    <span className="text-[14px] font-semibold text-gray-900">
                      {ocorrencia?.documento_transporte_id || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col text-left">
                    <span className="text-[14px] text-gray-500 mb-1">
                      Viagem
                    </span>
                    <span className="text-[14px] font-semibold text-gray-900">
                      {ocorrencia?.viagem_transporte_id || "--"}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 pb-4">
                  <div className="flex gap-4 items-center">
                    <div className="flex items-center gap-1">
                      <div>
                        <Calendar size={16} className="text-gray-500" />
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">
                          {formatDate(ocorrencia.dt_ocorrencia, "DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      <div>
                        <Clock size={16} className="text-gray-500" />
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">
                          {formatDate(ocorrencia.hora_ocorrencia, "HH:mm:ss")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full border-b border-dashed border-gray-200"></div>
                <div className="grid grid-cols-1 gap-4 pt-4">
                  <div className="flex flex-row gap-2">
                    <div>
                      <OcorrenciaIdBadge
                        ocorrencia_id={ocorrencia.ocorrencia_id}
                      />
                    </div>
                    <div>
                      {/* <Badge
                        variant="outline"
                        className="bg-gray-50 text-gray-700 border-gray-200"
                      >
                        Minuta
                      </Badge> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(ocorrencia.viagem_transporte_id ||
              ocorrencia.documento_transporte_id) && (
              <div className="rounded-lg border">
                <div className="divide-y divide-dashed divide-gray-200 p-4">
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex flex-row gap-2 text-left">
                      {ocorrencia.documento_transporte_id && (
                        <Badge
                          variant="outline"
                          className="bg-gray-50 text-blue-500 border-gray-200"
                        >
                          {
                            TipoDocumentoTransporte[
                              ocorrencia.tipo_documento_transporte!
                            ]
                          }
                        </Badge>
                      )}
                      {ocorrencia.viagem_transporte_id && (
                        <Badge
                          variant="outline"
                          className="bg-gray-50 text-blue-500 border-gray-200"
                        >
                          {
                            TipoDocumentoViagemTransporte[
                              ocorrencia.tipo_documento_viagem_transporte!
                            ]
                          }
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="rounded-lg border">
              <div className="divide-y divide-dashed divide-gray-200 p-4">
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex flex-col text-left">
                    <span className="text-[14px] text-gray-500 mb-1">
                      Observação
                    </span>
                    <span className="text-[14px] font-semibold text-gray-900">
                      {ocorrencia.observacoes}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {imageURL && (
              <div className="rounded-lg border">
                <div className="p-4 space-y-4">
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex flex-col">
                      <div className="text-[14px] text-gray-500 mb-2">
                        Fotos
                      </div>
                      <div>
                        <div className="rounded-lg flex py-2 px-4 justify-between bg-gray-100 border">
                          <a
                            download={ocorrencia.images[0]?.imageName}
                            href={imageURL}
                          >
                            {ocorrencia.images[0]?.imageName}
                          </a>
                          <a
                            download={ocorrencia.images[0]?.imageName}
                            href={imageURL}
                            className="text-blue-500"
                          >
                            <Download size={20} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {ocorrencia?.DocumentoOcorrenciaInfoAdicional && (
              <div className="rounded-lg border">
                <div className="p-4">
                  <h4 className="text-base font-semibold flex items-center gap-2 mb-4">
                    <ShipIcon size={18} />
                    <span>Informações da Embarcação</span>
                  </h4>
                  <div className="grid grid-cols-1 gap-4 pb-4">
                    <div className="flex flex-col text-left">
                      <span className="text-[14px] text-gray-500 mb-1">
                        Nome recebedor
                      </span>
                      <span className="text-[14px] font-semibold text-gray-900 break-all">
                        {
                          ocorrencia?.DocumentoOcorrenciaInfoAdicional
                            .nome_recebedor
                        }
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 pb-4">
                    <div className="flex flex-col text-left">
                      <span className="text-[14px] text-gray-500 mb-1">
                        Nome embarcação
                      </span>
                      <span className="text-[14px] font-semibold text-gray-900 break-all">
                        {
                          ocorrencia?.DocumentoOcorrenciaInfoAdicional
                            .nome_embarcacao
                        }
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 pb-4">
                    <div className="flex flex-col text-left">
                      <span className="text-[14px] text-gray-500 mb-1">
                        Data de saida
                      </span>
                      <span className="text-[14px] font-semibold text-gray-900 break-all flex items-center gap-2">
                        <Calendar size={16} className="text-gray-500" />
                        {formatDate(
                          ocorrencia?.DocumentoOcorrenciaInfoAdicional.dh_saida,
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </span>
                    </div>
                    <div className="flex flex-col text-left">
                      <span className="text-[14px] text-gray-500 mb-1">
                        Data de chegada
                      </span>
                      <span className="text-[14px] font-semibold text-gray-900 break-all flex items-center gap-2">
                        <Calendar size={16} className="text-gray-500" />
                        {formatDate(
                          ocorrencia?.DocumentoOcorrenciaInfoAdicional
                            .dh_chegada,
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </span>
                    </div>
                    {/* <div className="flex gap-4 items-center">
                      <span className="text-[14px] text-gray-500 mb-1">
                        Data embarcação
                      </span>
                      <div className="flex items-center gap-1">
                        <div>
                          <Calendar size={16} className="text-gray-500" />
                        </div>
                        <div>
                          <p className="text-sm text-gray-500">
                            {formatDate(
                              ocorrencia?.DocumentoOcorrenciaInfoAdicional
                                .dh_saida,
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirmar exclusão</DialogTitle>
            <DialogDescription>
              Você realmente deseja excluir esta entrega?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancelar
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Excluir
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <BottomSheet title="Editar entrega" isOpen={isOpen} onClose={onClose}>
        <EntregaForm onSave={onSave} entrega={ocorrencia} />
      </BottomSheet>
    </div>
  );
};

export default EntregasVisualizar;
