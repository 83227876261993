import { useState } from "react";
import { format, parseISO } from "date-fns";
import { CalendarIcon, ClockIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { pt } from "date-fns/locale";

import dayjs from "dayjs";

export interface DateTimePickerProps {
  name: string;
  value?: string;
  onChange: (date: string) => void;
}

const DateTimePicker = ({ value, onChange }: DateTimePickerProps) => {
  const parseDate = (dateString?: string) => {
    if (!dateString) return undefined;
    try {
      return parseISO(dateString);
    } catch (error) {
      console.error("Error parsing date:", error);
      return undefined;
    }
  };

  const initialDate = parseDate(value);
  const [date, setDate] = useState<Date | undefined>(initialDate);
  const [open, setOpen] = useState(false);
  const [tempDate, setTempDate] = useState<Date | undefined>(initialDate);
  const [time, setTime] = useState(() => {
    if (initialDate) {
      return format(initialDate, "HH:mm");
    }
    return "12:00";
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (open) {
      setTempDate(date || new Date());
    }
  };

  const handleConfirm = () => {
    if (tempDate) {
      const [hours, minutes] = time.split(":").map(Number);
      const newDate = new Date(tempDate);
      newDate.setHours(hours, minutes);
      setDate(newDate);

      if (onChange) {
        onChange(dayjs(newDate).format("YYYY-MM-DDTHH:mm:ss"));
      }
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="w-full">
        <Button
          type="button"
          variant="outline"
          onClick={() => setOpen(true)}
          className="w-full justify-start text-left font-normal text-base h-14 px-4 bg-white"
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            format(date, "dd/MM/yyyy HH:mm")
          ) : (
            <span className="text-muted-foreground">
              Seleciona uma data e horário
            </span>
          )}
        </Button>

        <Dialog open={open} onOpenChange={handleOpenChange}>
          <DialogContent
            aria-describedby="dialog-calendar"
            className="sm:max-w-[355px] p-0 overflow-hidden"
            showCloseButton={false}
          >
            <DialogHeader className="hidden">
              <DialogTitle />
            </DialogHeader>
            <div className="p-2">
              <Calendar
                mode="single"
                selected={tempDate}
                onSelect={setTempDate}
                locale={pt}
                className="w-full flex justify-center"
              />
            </div>
            <div className="border-t p-3">
              <div className="flex flex-col gap-2 w-full">
                <Label htmlFor="horario" className="text-xs">
                  Horário
                </Label>
                <div className="relative grow">
                  <Input
                    id="horario"
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className="peer appearance-none ps-9 [&::-webkit-calendar-picker-indicator]:hidden [&::-webkit-calendar-picker-indicator]:appearance-none"
                  />
                  <div className="text-gray-600/80 pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 peer-disabled:opacity-50">
                    <ClockIcon size={16} aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
            <DialogFooter className="px-4 py-4 border-t">
              <Button variant="outline" onClick={handleCancel} className="mr-2">
                Cancelar
              </Button>
              <Button variant={"default"} onClick={handleConfirm}>
                Confirmar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
DateTimePicker.displayName = "DateTimePicker";

export { DateTimePicker };
